















import isEqualBy from '@/tools/is-equal-by'

import ObservableForm from '@/components/shared/entities/observable-form.vue'

export default {
  name: 'observable-properties',
  props: ['value', 'error'],
  components: {
    'observable-form': ObservableForm,
  },
  data() {
    return {
      modified: this.value,
      original: this.value,
    }
  },
  mounted() {
    this.focusObservableForm()
  },
  methods: {
    focusObservableForm() {
      this.$nextTick(() => this.$refs.observableForm.focus())
    },

    onInput() {
      this.$emit('input', this.modified)
    },
    reset() {
      this.modified = this.original
    },
    apply() {
      this.$emit('apply')
    },
  },
  computed: {
    observableEdited() {
      return !isEqualBy(this.modified, this.original, ['name', 'definition', 'annotation'])
    },
  },
  watch: {
    value(val, oldVal) {
      if (val.id !== oldVal.id) {
        this.modified = val
        this.original = val
        this.focusNameInput()
      }
    },
  },
}
