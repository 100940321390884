























































import get from 'lodash/get'
import { get as getr, post, patch, del } from '@/services/api'
import { Observable } from '@/types'
import { AxiosResponse } from 'axios'

import bus from '@/services/event-bus'

import BnglText from '@/components/shared/bngl-text.vue'
import ObservableForm from '@/components/shared/entities/observable-form.vue'
import Split from '@/components/split.vue'
import Properties from '@/components/model-page/secondary-view/observable-properties.vue'

import findUniqName from '@/tools/find-uniq-name'
import objStrSearchFilter from '@/tools/obj-str-search-filter'
import blockHeightWoPadding from '@/tools/block-height-wo-padding'

const defaultObservable = {
  id: undefined,
  name: '',
  valid: false,
  definition: '',
  annotation: '',
}

const searchProps = ['name', 'definition']

export default {
  name: 'observables-component',
  components: {
    split: Split,
    properties: Properties,
    'observable-form': ObservableForm,
  },
  data() {
    return {
      error: false,
      deleteError: false,
      observables: [],
      searchStr: '',
      tableHeight: null,
      newObservableModalVisible: false,
      current: { ...defaultObservable },
      columns: [
        {
          title: 'Name',
          key: 'name',
          maxWidth: 400,
        },
        {
          title: 'BioNetGen definition',
          render: (h, params) =>
            h(BnglText, {
              props: {
                entityType: 'observable',
                value: params.row.definition,
              },
            }),
        },
        {
          title: 'Annotation',
          render: (h, params) => h('span', get(params, 'row.annotation', '').split('\n')[0]),
        },
      ],
    }
  },
  async created() {
    this.observables = await this.getObservables()
  },
  mounted() {
    this.timeoutId = window.setTimeout(() => this.computeTableHeight(), 0)
    bus.$on('layoutChange', () => this.computeTableHeight())
  },
  beforeDestroy() {
    window.clearTimeout(this.timeoutId)
    bus.$off('layoutChange')
  },
  methods: {
    async getObservables() {
      const model = this.$store.state.model

      if (!model?.id) return []

      const res: AxiosResponse<Observable[]> = await getr('observables', {
        user_id: model?.user_id,
        model_id: model?.id,
      })

      return res.data
    },

    add() {
      this.current = {
        ...defaultObservable,
        name: findUniqName(this.observables, 'o'),
      }
      this.showNewObservableModal()

      this.$nextTick(() => {
        this.$refs.observableForm.refresh()
        this.$refs.observableForm.focus()
      })
    },

    async remove() {
      const res = await del<null>(`observables/${this.current.id}`)
      if (!res) {
        this.deleteError = true
        return
      }

      this.deleteError = false

      this.current = { ...defaultObservable }
      this.observables = await this.getObservables()
    },
    showNewObservableModal() {
      this.newObservableModalVisible = true
    },
    hideNewObservableModal() {
      this.newObservableModalVisible = false
    },
    onObservableSelect(observable: Observable) {
      this.current = observable
    },
    async onOk() {
      this.error = false

      const model_id = this.$store.state.model?.id
      let res: AxiosResponse<Observable> | undefined

      if (!this.current.id) res = await post<Observable>('observables', { ...this.current, model_id })
      else res = await patch<Observable>(`observables/${this.current.id}`, this.current)

      if (!res) {
        this.error = true
        return
      }

      this.hideNewObservableModal()

      this.observables = await this.getObservables()
    },
    computeTableHeight() {
      const main = this.$refs.mainBlock
      if (main) this.tableHeight = blockHeightWoPadding(this.$refs.mainBlock)
    },
  },
  computed: {
    filteredEntities() {
      return this.observables.filter((e) => objStrSearchFilter(this.searchStr, e, { include: searchProps }))
    },
    emptyTableText() {
      return this.searchStr ? 'No matching observables' : 'Create a observable by using buttons below'
    },
  },
}
